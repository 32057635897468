<template>
  <b-modal
    :id="id"
    size="md"
    title="Thêm tổ chức cung cấp dịch vụ"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="handleCreateService"
    @hidden="resetState"
    @show="fetchComboboxType"
  >
    <div class="modal-accept">
      <validation-observer ref="rule">
        <b-form class="content-form">
          <b-form-group>
            <label for="basicInput">Dịch vụ ATVSLĐ <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="reportTypeId"
              rules="required"
              :custom-messages="{required: `Dịch vụ ATVSLĐ là bắt buộc`}"
            >
              <v-select
                v-model="dataInput.organizationOSHId"
                :reduce="title => title.id"
                label="name"
                :options="dataCombobox || []"
                :placeholder="'Cấp doanh nghiệp'"
                :state="errors.length > 0 ? false:null"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="basicInput">Dịch vụ y tế <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="reportTypeId"
              rules="required"
              :custom-messages="{required: `Dịch vụ y tế là bắt buộc`}"
            >
              <v-select
                v-model="dataInput.organizationHealthCareId"
                :reduce="title => title.id"
                label="name"
                :options="dataCombobox || []"
                :placeholder="'Cấp doanh nghiệp'"
                :state="errors.length > 0 ? false:null"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="InputHelp">Năm</label>
            <b-form-input v-model="dataInput.year" />
          </b-form-group>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm, BFormTextarea, BButton, BFormCheckbox,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VSelect from 'vue-select'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import DataFilter from '../../constants/DataFilter'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BRow,
    BFormCheckbox,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormTextarea,
    BButton,
    VSelect,
    DateTimePicker,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      dataFilterType: DataFilter.dataFilterType,
      customCode: {
        required: 'Hạn nộp là bắt buộc',
      },
      customSelectVali: {
        required: 'Loại báo cáo là bắt buộc',
      },
      dataInput: {
        organizationOSHId: '',
        year: 0,
        organizationHealthCareId: '',
      },
      dataCombobox: [],
    }
  },
  methods: {
    handleCreateService(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleCreateService', this.dataInput)
        }
      })
    },
    resetState() {
      this.dataInput = {
        organizationOSHId: '',
        year: 0,
        organizationHealthCareId: '',
      }
    },

    async fetchComboboxType() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_BUSINESS_SERVICE)
      this.dataCombobox = data?.data
    },
  },
}
</script>

<style lang="scss">
.modal-accept {
  .action {
    display: flex;
    justify-content: end;
  }
  .btn-button-file {
    width: 40%;
    height: 38px;
  }
      .input-file {
      display: none;
    }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.id,"size":"lg","title":"Thêm ngành nghề kinh doanh","cancel-title":"Hủy bỏ","cancel-variant":"outline-primary","ok-title":"Lưu"},on:{"ok":_vm.addGroupUser}},[_c('div',{staticClass:"header-action"},[_c('search',{on:{"change":_vm.search}})],1),_c('vue-good-table',{attrs:{"style-class":"vgt-table","columns":_vm.columnModal,"rows":_vm.dataListTable || [],"select-options":{
      enabled: true,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }},on:{"on-selected-rows-change":_vm.selectRowTableModal},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'code')?_c('span',{staticClass:"text-nowrap"},[_c('b-badge',{staticClass:"light-code-group",attrs:{"pill":""}},[_c('span',{staticClass:"text-name"},[_vm._v(_vm._s(_vm.$t(props.row.code)))])])],1):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord,"currentPage":_vm.queryGetList.pageNumber},on:{"pageClick":_vm.pageChangeModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
/* eslint-disable no-bitwise */
<template>
  <div class="infomation-and-adress">
    <information-and-adress-title
      :icon="'InfoIcon'"
      :title="'Thông tin tài khoản'"
    />
    <div class="infomation-and-adress__input__01">
      <b-row>
        <!--===============  input tài khoản=================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="userName"
          >
            <label
              for="userName"
            >Tên đăng nhập</label>
            <b-form-input
              id="userName"
              v-model="account"
              disabled
            />
          </b-form-group>
        </b-col>
        <!--===============  input Mật khẩu=================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="newPassword"
          >
            <label
              for="newPassword"
            >Mật khẩu</label>
            <b-form-input
              id="newPassword"
              v-model="dataInput.newPassword"
            />
          </b-form-group>
        </b-col>
        <!--===============  input Nhập lại mật khẩu=================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="againPassworks"
          >
            <label
              for="againPassworks"
            >Nhập lại mật khẩu</label>
            <b-form-input
              id="againPassworks"
              v-model="dataInput.confirmNewPassword"
              @keyup="checkPassword"
            />
            <small
              v-if="againPassworks"
              class="text-danger"
            > Nhập lại mật khẩu không đúng</small>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import {
  BRow, BCol, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import InformationAndAdressTitle from '../components/InformationAndAdressTitle.vue'
import BusinessSectorSelect from '@/views/management-business/business-sector/pages/components/BusinessSectorSelect.vue'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BFormGroup,
    InformationAndAdressTitle,
    BRow,
    BCol,
    BusinessSectorSelect,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    DateTimePicker,
    vSelect,
  },
  props: {
    account: {
      type: String,
      default: '',
    },
    userId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dataInput: {
        confirmNewPassword: '',
        newPassword: '',
      },
      againPassworks: false,
    }
  },
  methods: {
    // Check nhập lại mật khẩu
    checkPassword() {
      if (this.dataInput.newPassword !== this.dataInput.confirmNewPassword && this.dataInput.newPassword !== '') {
        this.againPassworks = true
      } else {
        this.againPassworks = false
      }

      if (this.dataInput.confirmNewPassword === '') {
        this.againPassworks = false
      }
    },
  },
}
</script>

<style lang="scss">
.infomation-and-adress{
  padding: 20px;
}
</style>

<template>
  <div class="Representative-container">
    <b-row>
      <!--===============  input Người đại diện =================-->
      <b-col
        md="4"
        xl="4"
      >
        <b-form-group
          label-for="officalProfileId"
        >
          <label
            for="officalProfileId"
          >Người đại diện<span
            class="required"
          >(*)</span></label>
          <validation-provider
            #default="{ errors }"
            rules="required"
            :custom-messages="{required: `Người đại diện là bắt buộc`,}"
          >
            <v-select
              v-model="valueID.officalProfileId"
              :reduce="data => data.id"
              :label="'name'"
              :placeholder="`Lựa chọn người đại diện`"
              :options="dataId"
              :class="{'is-invalid': !!errors.length}"
              :state="errors.length > 0 ? false:null"
              @input="selectUser"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <!--===============  input Ngày sinh =================-->
      <b-col
        md="4"
        xl="4"
      >
        <b-form-group
          label-for="birthDay"
        >
          <label
            for="birthDay"
          >Ngày sinh</label>
          <b-form-input
            id="birthDay"
            v-model="dataUserInfor.birthDay"
            :disabled="true"
          />
        </b-form-group>
      </b-col>
      <!--===============  input Số CMND=================-->
      <b-col
        md="4"
        xl="4"
      >
        <b-form-group
          label-for="identityCardNumber"
        >
          <label
            for="identityCardNumber"
          >Số CMND</label>
          <b-form-input
            id="identityCardNumber"
            v-model="dataUserInfor.identityCardNumber"
            type="number"
            :disabled="true"
          />
        </b-form-group>
      </b-col>
      <!--===============  input Giới tính=================-->
      <b-col
        md="4"
        xl="4"
      >
        <label>Giới tính</label>
        <div
          class="demo-inline-spacing"
        >
          <b-form-radio
            v-model="dataUserInfor.gender"
            :value="true"
            :disabled="true"
          >
            Nam
          </b-form-radio>
          <b-form-radio
            v-model="dataUserInfor.gender"
            :value="false"
            :disabled="true"
          >
            Nữ
          </b-form-radio>
        </div>
      </b-col>
      <!--===============  input Ngày cấp=================-->
      <b-col
        md="4"
        xl="4"
      >
        <b-form-group
          label-for="dateIssuedString"
        >
          <label
            for="dateIssuedString"
          >Ngày cấp</label>
          <b-form-input
            id="dateIssuedString"
            v-model="dataUserInfor.dateIssuedString"
            :disabled="true"
          />
        </b-form-group>
      </b-col>
      <!--===============  input Nơi cấp=================-->
      <b-col
        md="4"
        xl="4"
      >
        <b-form-group
          label-for="locationIssued"
        >
          <label
            for="locationIssued"
          > Nơi cấp</label>
          <b-form-input
            id="locationIssued"
            v-model="dataUserInfor.locationIssued"
            :disabled="true"
          />
        </b-form-group>
      </b-col>
      <!--===============  input Địa chỉ thường trú=================-->
      <b-col
        md="4"
        xl="4"
      >
        <b-form-group
          label-for="address"
        >
          <label
            for="address"
          > Địa chỉ thường trú</label>
          <b-form-input
            id="address"
            v-model="dataUserInfor.address"
            :disabled="true"
          />
        </b-form-group>
      </b-col>
      <!--===============  input Điện thoại=================-->
      <b-col
        md="4"
        xl="4"
      >
        <b-form-group
          label-for="phoneNumber"
        >
          <label
            for="phoneNumber"
          > Điện thoại</label>
          <b-form-input
            id="phoneNumber"
            v-model="dataUserInfor.phoneNumber"
            type="number"
            :disabled="true"
          />
        </b-form-group>
      </b-col>
      <!--===============  input Email=================-->
      <b-col
        md="4"
        xl="4"
      >
        <b-form-group
          label-for="Email"
        >
          <label
            for="Email"
          >Email</label>
          <b-form-input
            id="Email"
            v-model="dataUserInfor.email"
            :disabled="true"
          />
        </b-form-group>
      </b-col>
      <!--===============  input Học hàm=================-->
      <b-col
        md="4"
        xl="4"
      >
        <b-form-group
          label-for="academicDegree"
        >
          <label
            for="academicDegree"
          >Học hàm</label>
          <b-form-input
            id="academicDegree"
            v-model="dataUserInfor.academicDegree"
            :disabled="true"
          />
        </b-form-group>
      </b-col>
      <!--===============  input Học vị=================-->
      <b-col
        md="4"
        xl="4"
      >
        <b-form-group
          label-for="regency"
        >
          <label
            for="regency"
          >Học vị</label>
          <b-form-input
            id="regency"
            v-model="dataUserInfor.regency"
            :disabled="true"
          />
        </b-form-group>
      </b-col>
      <!--===============  input Mã số thuế=================-->
      <b-col
        md="4"
        xl="4"
      >
        <b-form-group
          label-for="taxNumber"
        >
          <label
            for="taxNumber"
          >Mã số thuế</label>
          <b-form-input
            id="taxNumber"
            v-model="dataUserInfor.taxNumber"
            type="number"
            :disabled="true"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormRadio, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import { formatDateToDDMM } from '@core/utils/filter'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BRow, BCol, BFormRadio, vSelect, BFormGroup, ValidationProvider, ValidationObserver, BFormInput,
  },

  filters: {
    formatDateToDDMM,
  },
  props: {
    officalProfileOtherId: {
      type: String,
      default: '',
    },
    dataRepresent: {
      type: Array,
      default: _ => [],
    },
  },
  data() {
    return {
      valueID: {
        officalProfileId: '',
      },
      dataId: [],
      dataUserInfor: {},
    }
  },
  watch: {
    officalProfileOtherId(val) {
      if (val) {
        this.valueID.officalProfileId = this.officalProfileOtherId
        this.fetchdata(val)
      }
    },
    dataRepresent(val) {
      if (val) {
        this.dataId = this.dataRepresent
      }
    },
  },
  async created() {
    const data01 = await this.fecthDataOfficalProfileCombobox()
    this.dataId = data01.data
  },
  methods: {
    ...mapActions('business', ['fecthDataOfficalProfileCombobox', 'fetchUserId']),
    async fetchdata(data) {
      const user = await this.fetchUserId(data)
      this.dataUserInfor = {
        ...user,
        birthDay: formatDateToDDMM(user.birthDay),
        dateIssuedString: formatDateToDDMM(user.dateIssuedString),
      }
    },
    async selectUser(val) {
      this.valueID.officalProfileId = val
      await this.fetchdata(this.valueID.officalProfileId)
    },
  },

}
</script>

<style>
.Representative-container{
  padding:20px
}

</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.id,"size":"md","title":"Thêm tổ chức cung cấp dịch vụ","cancel-title":"Hủy bỏ","cancel-variant":"outline-primary","ok-title":"Lưu","centered":"","no-close-on-backdrop":""},on:{"ok":_vm.handleCreateService,"hidden":_vm.resetState,"show":_vm.fetchComboboxType}},[_c('div',{staticClass:"modal-accept"},[_c('validation-observer',{ref:"rule"},[_c('b-form',{staticClass:"content-form"},[_c('b-form-group',[_c('label',{attrs:{"for":"basicInput"}},[_vm._v("Dịch vụ ATVSLĐ "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"reportTypeId","rules":"required","custom-messages":{required: "Dịch vụ ATVSLĐ là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (title) { return title.id; },"label":"name","options":_vm.dataCombobox || [],"placeholder":'Cấp doanh nghiệp',"state":errors.length > 0 ? false:null},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataInput.organizationOSHId),callback:function ($$v) {_vm.$set(_vm.dataInput, "organizationOSHId", $$v)},expression:"dataInput.organizationOSHId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{attrs:{"for":"basicInput"}},[_vm._v("Dịch vụ y tế "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"reportTypeId","rules":"required","custom-messages":{required: "Dịch vụ y tế là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (title) { return title.id; },"label":"name","options":_vm.dataCombobox || [],"placeholder":'Cấp doanh nghiệp',"state":errors.length > 0 ? false:null},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataInput.organizationHealthCareId),callback:function ($$v) {_vm.$set(_vm.dataInput, "organizationHealthCareId", $$v)},expression:"dataInput.organizationHealthCareId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Năm")]),_c('b-form-input',{model:{value:(_vm.dataInput.year),callback:function ($$v) {_vm.$set(_vm.dataInput, "year", $$v)},expression:"dataInput.year"}})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
export default {
  DATA_BUSINESS_TYPE: '/BussinessType/get-combobox-bussiness-type',
  DATA_OFFICAL_PROFILE_COMBOBOX: '/OfficalProfile/get-combobox-offical-profile',
  FETCH_USER_ID: 'OfficalProfile/officalProfiles/',
  LIST_PROVINCES: 'OfficalProfile/provinces',
  FECTH_DISTRICTS: 'OfficalProfile/districts/',
  FECTH_WARDS: 'OfficalProfile/wards/',
  ADD_BUSINESS: 'Bussiness/bussiness',
  INFORMATION_BUSINESS: 'Bussiness/infoBussiness',
  LIST_BUSINESS_SECTOR: 'Bussiness/list-sector-in-bussiness',
  LIST_BUSINESS_NOT_SECTOR: 'Bussiness/list-sector-not-register',
  DELETE_BUSINESS_SECTOR: 'Bussiness/delete-sector-in-bussiness',
  ADD_BUSINESS_SECTOR: 'Bussiness/add-sector-to-bussiness',
  LIST_BUSINESS_SERVICE: 'Bussiness/list-bussiness-service',
  ADD_BUSINESS_SERVICE: 'Bussiness/add-bussiness-service',
  DELETE_BUSINESS_SERVICE: 'Bussiness/delete-bussiness-service',
  COMBOBOX_BUSINESS_SERVICE: 'Bussiness/get-combobox-bussiness',
  CHANGE_PASSWORD: 'Account/reissue-password',
  COMBOBOX_POSITION: 'Position/get-combobox-position',
  CREATE_USERS: 'OfficalProfile/officalProfiles',
}

<template>
  <div class="mb-2">
    <button-all-header
      :contentBtnAdd="'Thêm ngành nghề kinh doanh'"
      :arrayExcel="[]"
      :showBtnMultiDelete="showBtnMultiDelete"
      :hideImportFile="false"
      :hideExportFile="false"
      :hideDowload="false"
      @clickAdd="showModalCreate"
      @clickDelete="deleteItems"
      @search="search($event)"
    />

    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataTable || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column:Chức năng -->
        <span v-if="props.column.field === 'actionFunction'">
          <span
            class="ml-2"
            @click="deleteItem(props.row.id)"
          >
            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="text-body"
            />
          </span>
        </span>
      </template>
    </vue-good-table>

    <!-- pagination -->
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="handlePageClick"
    />

    <!-- modal thêm nhóm người dùng -->
    <modal-add-business
      :id="modalAddBusiness"
      :dataListTable="dataListTable"
      :totalRecord="totalRecordNotRegister"
      :queryGetList="queryGetList"
      @addGroupUser="addGroupUserHandle"
      @search="searchUsers"
      @pageChangeModal="pageChangeModal"
    />

    <!-- modal xóa người dùng-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import { mapActions, mapGetters } from 'vuex'
import {
  BBadge,
  BMedia,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ModalAddBusiness from '../components/ModalAddBusiness.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    BBadge,
    BMedia,
    MyPagination,
    BDropdown,
    BDropdownItem,
    ButtonAllHeader,
    ConfirmModal,
    ModalAddBusiness,
  },
  filters: {
    formatDateToDDMM,
  },
  props: {
    businessId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formatFullName,
      columns: [
        {
          label: 'MÃ Cấp 1',
          field: 'level1',
          sortable: false,
        },
        {
          label: 'MÃ Cấp 2',
          field: 'level2',
          sortable: false,
        },
        {
          label: 'MÃ cấp 3',
          field: 'level3',
          sortable: false,
        },
        {
          label: 'MÃ cấp 4',
          field: 'level4',
          sortable: false,
        },
        {
          label: 'MÃ cấp 5',
          field: 'level5',
          sortable: false,
        },
        {
          label: 'TÊN NGÀNH NGHỀ KINH DOANH',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Chức năng',
          field: 'actionFunction',
          sortable: false,
          tdClass: 'actiontd',
          thClass: 'actionth',
          width: '175px',
        },
      ],
      currentPage: 0,
      urlQuery: {
        bussinessId: this.businessId,
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal',
      modalAddBusiness: 'confirm-add-business',
      modalContent: '',
      showBtnMultiDelete: false,
      deleteIds: [],
      dataTable: [],
      totalRecord: 0,
      queryGetList: {
        bussinessId: this.businessId,
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      dataListTable: [],
      totalRecordNotRegister: 0,
    }
  },
  created() {
    this.fetchListSectorBusiness(this.urlQuery)
    this.fetchListAddBussiness(this.queryGetList)
  },
  methods: {
    // Lấy danh sách thêm nhóm người dùng
    async fetchListAddBussiness(queryGetList) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_BUSINESS_NOT_SECTOR, { params: queryGetList })
      this.dataListTable = data?.data?.pageLists
      this.totalRecordNotRegister = data?.data?.totalRecord
      this.$hideLoading()
    },

    // Lấy danh sách daonh nghiệp trong phiên giao dịch việc làm
    async fetchListSectorBusiness(urlQuery) {
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_BUSINESS_SECTOR, { params: urlQuery })
      this.dataTable = data.data.pageLists
      this.totalRecord = data.data.totalRecord
    },

    // Xử lý khi ấn nút phân trang
    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchListSectorBusiness(this.urlQuery)
    },

    // Phân trang danh sách modal nhóm người dùng
    pageChangeModal(pageNumber, pageSize) {
      this.queryGetList.pageNumber = pageNumber
      this.queryGetList.pageSize = pageSize
      this.fetchListAddBussiness(this.queryGetList)
    },

    // tìm kiếm lộ trình đào tạo
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchListSectorBusiness(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchListSectorBusiness(this.urlQuery)
      }
    },

    // tìm kiếm nhóm người dùng chưa đăng ký
    searchUsers(val) {
      if (val != null) {
        this.queryGetList.pageNumber = 1
        this.queryGetList.key = val
        this.fetchListAddBussiness(this.queryGetList)
      } else {
        this.queryGetList.pageNumber = 1
        this.queryGetList.key = ''
        this.fetchListAddBussiness(this.queryGetList)
      }
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // Mở modal nhóm người dùng
    showModalCreate() {
      this.$bvModal.show(this.modalAddBusiness)
    },

    // xóa 1 người dùng
    async deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xóa người dùng của hệ thống
    async deleteAction() {
      const payload = {
        ids: this.deleteIds,
        bussinessId: this.businessId,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_BUSINESS_SECTOR, payload).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchListSectorBusiness(this.urlQuery)
        this.fetchListAddBussiness(this.queryGetList)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thất bại', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Thêm nhóm người dùng vào 1 tài khaonr người dùng
    async addGroupUserHandle(val) {
      const payload = {
        bussinessId: this.businessId,
        bussinessSectors: val,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.ADD_BUSINESS_SECTOR, payload).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Thêm thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchListSectorBusiness(this.urlQuery)
        this.fetchListAddBussiness(this.queryGetList)
        this.$bvModal.hide(this.modalAddBusiness)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Thêm thất bại', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.modalAddBusiness)
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.actiontd{
  text-align: center;
}
.actionth{
  text-align: center;
}

.text-name {
  color: #227ff4;
}

.light-code {
  background: rgba(34, 127, 244, 0.12);
  border-radius: 4px;
}

.border-status {
  border-radius: 4px;
}

.button-icon {
  .btn-component {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }
}

.search-input {
  width: 400px;
}
</style>

<template>
  <div
    id="addBusiness"
  >
    <my-tabs
      :listTabs="listTabs"
      defaultActive="business-info"
      typeCss="active-full"
      @setActive="setActive"
    >
    </my-tabs>
    <div class="page-container addBusiness-fix">
      <validation-observer ref="rule">
        <information-and-adress
          v-if="isRendered('business-info')"
          id="user-add"
          ref="informationAndAdress"
          :dataInforBusiness="dataInforBusiness"
          class="tab-pane"
          :class="{'active':activeTab==='business-info'}"
        />
        <information-account
          v-if="isRendered('information-account')"
          id="user-add"
          ref="informationAccount"
          :account="dataInforBusiness.userName"
          class="tab-pane"
          :class="{'active':activeTab==='information-account'}"
        />
        <OtherBusiness
          v-if="isRendered('business-other')"
          id="user-add"
          :businessId="dataInforBusiness.id"
          class="tab-pane pt-2"
          :class="{'active':activeTab==='business-other'}"
        />
        <representative
          v-if="isRendered('business-Representative')"
          id="user-add"
          ref="representative"
          :dataRepresent="dataId"
          :officalProfileOtherId="dataInforBusiness.officalProfileId"
          class="tab-pane"
          :class="{'active':activeTab==='business-Representative'}"
        />
        <organization-service
          v-if="isRendered('organization-service')"
          id="user-add"
          :businessId="dataInforBusiness.id"
          class="tab-pane"
          :class="{'active':activeTab==='organization-service'}"
        />
      </validation-observer>
      <div class="add-business__button">
        <div class="add-business__button__item">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="save()"
          >
            Lưu lại
          </b-button>
          <b-button
            v-if="isShowButton"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="ml-50"
            variant="outline-primary"
            @click="showAddOther"
          >
            Thêm người đại diện
          </b-button>
        </div>
        <ModalCreateRepresentative
          :id="modalCreateRepresent"
          @handleCreate="handleCreate"
        />
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import {
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import InformationAndAdress from './tag-list/InformationAndAdress.vue'
import Representative from './tag-list/Representative.vue'
import OtherBusiness from './tag-list/OtherBusiness.vue'
import OrganizationService from './tag-list/OrganizationService.vue'
import ModalCreateRepresentative from './tag-list/ModalCreateRepresentative.vue'
import InformationAccount from './tag-list/InformationAccount.vue'
import MyTabs from '@/components/tabs/MyTabs.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    InformationAndAdress,
    BButton,
    MyTabs,
    Representative,
    OtherBusiness,
    ValidationProvider,
    ValidationObserver,
    OrganizationService,
    InformationAccount,
    ModalCreateRepresentative,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      modalCreateRepresent: 'modal-create-represent',
      activeTab: null,
      listTabs: [
        {
          key: 'business-info',
          title: 'Thông tin doanh nghiệp',
          icon: 'BriefcaseIcon',
          isDisabled: false,
          isRendered: false,
        },
        {
          key: 'information-account',
          title: 'Thông tin tài khoản',
          icon: 'UserIcon',
          isDisabled: false,
          isRendered: false,
        },
        {
          key: 'business-Representative',
          title: 'Thông tin người đại diện',
          icon: 'UsersIcon',
          isDisabled: false,
          isRendered: false,
        },
        {
          key: 'business-other',
          title: 'Ngành nghề kinh doanh khác',
          icon: 'PocketIcon',
          isDisabled: false,
          isRendered: false,
        },
        {
          key: 'organization-service',
          title: 'Tổ chức cung cấp dịch vụ',
          icon: 'PackageIcon',
          isDisabled: false,
          isRendered: false,
        },
      ],
      dataInforBusiness: {},
      isShowButton: false,
      dataId: [],
    }
  },
  watch: {
    $route(to, from) {
      if (to && to.params.type) {
        this.activeTab = to.params.type
        const tab = this.listTabs.find(x => x.key === this.activeTab)
        if (tab) tab.isRendered = true
      }
    },
  },
  created() {
    this.fetchDataInforBusiness()
    if (this.activeTab === null) {
      this.activeTab = 'business-info'
      this.listTabs[0].isRendered = true
      this.listTabs[1].isRendered = true
      this.listTabs[2].isRendered = true
    }
  },
  methods: {
    ...mapActions('business', ['fecthDataOfficalProfileCombobox', 'fetchUserId']),

    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },
    // thay đổi active tab
    setActive(activeTab) {
      this.activeTab = activeTab
      const tab = this.listTabs.find(x => x.key === activeTab)
      if (tab) tab.isRendered = true
      if (this.activeTab === 'business-Representative') {
        this.isShowButton = true
      } else {
        this.isShowButton = false
      }
    },

    // Lấy thông tin doanh nghiệp
    async fetchDataInforBusiness() {
      const { data } = await axiosApiInstance.get(ConstantsApi.INFORMATION_BUSINESS)
      this.dataInforBusiness = data
    },

    // Mở modal tạo người đại diện
    showAddOther() {
      this.$bvModal.show(this.modalCreateRepresent)
    },

    // Tạo người đại diện
    async handleCreate(data) {
      await axiosApiInstance.post(ConstantsApi.CREATE_USERS, data).then(async res => {
        if (res.status === 200) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Cập nhật thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$bvModal.hide(this.modalCreateRepresent)
          const data01 = await this.fecthDataOfficalProfileCombobox()
          this.dataId = data01?.data
          this.$refs.representative.selectUser(res?.data?.data.id)
        }
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast(e.response.data?.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    //* *******edit ********8 */
    // eslint-disable-next-line consistent-return
    async updatedInforBusiness(dataAdd) {
      try {
        this.$showAllPageLoading()
        const PostEditJod = await axiosApiInstance.put(ConstantsApi.ADD_BUSINESS, dataAdd)
        if (PostEditJod.status === 200) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Sửa thành công!', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      } catch (e) {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast(e.response.data?.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },

    async save() {
      this.$refs.rule.validate().then(async success => {
        if (success) {
          const data = {
            ...this.$refs.informationAndAdress.valueData,
            ...this.$refs.representative.valueID,
            id: this.dataInforBusiness.id,
            dateExpiredString: this.$refs.informationAndAdress.valueData.dateExpiredString === ''
              ? null
              : this.$refs.informationAndAdress.valueData.dateExpiredString,
          }
          const payload = {
            ...this.$refs.informationAccount.dataInput,
            userId: this.dataInforBusiness.userId,
          }
          await this.updatedInforBusiness(data)
          if (this.$refs.informationAccount.dataInput.newPassword !== '') {
            await axiosApiInstance.post(ConstantsApi.CHANGE_PASSWORD, payload)
          }
        } else {
          let { errors } = this.$refs.rule
          errors = Object.values(errors).filter(item => item[0]?.includes('là bắt buộc'))
          errors = errors.map((item, index) => {
            if (index !== 0) {
              return ` ${item[0].slice(0, item[0].length - 12)}`
            }
            return item[0].slice(0, item[0].length - 12)
          })
          this.$root.$bvToast.toast(`Vui lòng điền thông tin ${errors}`, {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
#addBusiness{
   .add-business__button{
     padding: 0px 0px 20px 20px;
     display: flex;
     &__item{
       margin-right: 20px;
     }
   }
   .addBusiness-fix{
     padding: 0px;
   }
}

</style>

<template>
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      ok-title="Lưu"
      cancel-title="Hủy bỏ"
      centered
      size="xl"
      no-close-on-backdrop
      :title="title"
      @ok="handleCreate($event)"
      @hidden="resetData"
    >
      <my-tabs
        :listTabs="listTabs"
        defaultActive="user-edit-tab-account"
        typeCss="active-full"
        @setActive="setActive"
      >
      </my-tabs>
      <validation-observer ref="rule">
        <tab-info-obligatoory
          v-if="isRendered('user-edit-tab-account')"
          id="user-add"
          ref="info"
          :accountInformation="accountInformation"
          class="tab-pane"
          :class="{'active':activeTab==='user-edit-tab-account'}"
          :disableProp="disableProp"
        />

        <tab-info-user
          v-if="isRendered('user-edit-tab-information')"
          id="user-add"
          ref="user"
          :inforUser="inforUser"
          class="tab-pane"
          :class="{'active':activeTab==='user-edit-tab-information'}"
          :disableProp="disableProp"
        />
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>

import {
  BModal,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MyTabs from '@/components/tabs/MyTabs.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BModal,
    ValidationObserver,
    MyTabs,
    TabInfoObligatoory: () => import('../components/TabInfoObligatory.vue'),
    TabInfoUser: () => import('../components/TabInfoUser.vue'),
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      disableProp: false,
      collectReportId: null,
      activeTab: null,
      listTabs: [
        {
          key: 'user-edit-tab-account',
          title: 'Thông tin bắt buộc',
          icon: 'InfoIcon',
          isRendered: false,
        },
        {
          key: 'user-edit-tab-information',
          title: 'Thông tin người dùng',
          icon: 'FileIcon',
          isRendered: false,
        },
      ],
      inforUser: {
        birthDayString: '',
        phoneNumber: '',
        address: '',
        placeOfBirth: '',
        academicDegree: '',
        academicRank: '',
        idOrganizationStructure: '',
        identityCardNumber: '',
        dateIssuedString: '',
        regency: '',
        locationIssued: '',
        taxNumber: '',
        positionId: '',
      },
      accountInformation: {
        avatar: '',
        code: '',
        gender: true,
        email: 'user@example.com',
        name: '',
        userName: '',
        password: '',
        userTypeId: JSON.parse(localStorage.getItem('userData')).userTypeId,
        rePassword: '',
        isDepartment: false,
      },
    }
  },
  watch: {
    $route(to, from) {
      if (to && to.params.type) {
        this.activeTab = to.params.type
        const tab = this.listTabs.find(x => x.key === this.activeTab)
        if (tab) tab.isRendered = true
      }
    },

    activeTab(val) {
      if (val === null) {
        this.activeTab = 'user-edit-tab-account'
        this.listTabs[0].isRendered = true
      }
    },
  },

  async created() {
    if (this.$route.params.type) {
      this.activeTab = this.$route.params.type
      const tab = this.listTabs.find(x => x.key === this.activeTab)
      if (tab) tab.isRendered = true
    }
    if (this.activeTab === null) {
      this.activeTab = 'user-edit-tab-account'
      this.listTabs[0].isRendered = true
    }
  },
  methods: {

    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },
    // thay đổi active tab
    setActive(activeTab) {
      this.activeTab = activeTab
      const tab = this.listTabs.find(x => x.key === activeTab)
      if (tab) tab.isRendered = true
    },

    handleCreate(bvModalEvt) {
      const model = {
        ...this.inforUser,
        ...this.accountInformation,
      }
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(async success => {
        if (success) {
          this.$emit('handleCreate', model)
        }
      })
    },

    resetData() {
      this.activeTab = null
      this.inforUser = {
        birthDayString: '',
        phoneNumber: '',
        address: '',
        placeOfBirth: '',
        academicDegree: '',
        academicRank: '',
        idOrganizationStructure: '',
        identityCardNumber: '',
        dateIssuedString: '',
        regency: '',
        locationIssued: '',
        taxNumber: '',
        positionId: '',
      }
      this.accountInformation = {
        avatar: '',
        code: '',
        gender: true,
        email: 'user@example.com',
        name: '',
        userName: '',
        password: '',
        userTypeId: null,
        rePassword: '',
        isDepartment: false,
      }
    },
  },
}
</script>
<style lang="scss">
  .btn-down {
    display: grid;
    margin-bottom: 15px;
    align-items: end;
  }
</style>
